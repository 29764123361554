import * as React from "react"
import { Accordion } from "./accordion"


export const Faq = () => {
  const config  = [
    {
      title:' Сколько времени вы&nbsp;делаете ремонт в&nbsp;квартире? В&nbsp;доме?',
      text:'<p>Чаще всего, если мы&nbsp;имеем дело с&nbsp;дизайном интерьера&nbsp;— приходится говорить не&nbsp;просто о&nbsp;ремонте, а&nbsp;о&nbsp;реконструкции, которой мы&nbsp;занимаемся совместно с&nbsp;нашими партнерами, высококлассными строителями. Для того чтобы воплотить в&nbsp;жизнь хороший интерьер (не&nbsp;путать с&nbsp;«евроремонтом»), обычно уходит от&nbsp;6&nbsp;до&nbsp;12&nbsp;месяцев.</p>'
    },    {
      title:' Можно&nbsp;ли заказать в&nbsp;вашей студии ремонт или услуги по&nbsp;строительству без дизайн-проекта или архитектурного проекта?',
      text:'<p>Нет. Нам не&nbsp;интересен ремонт ради ремонта, нам интересно создавать уникальные объекты интерьера и&nbsp;архитектуры.</p>'
    },
  ]
  return (
      <Accordion  config={config}/>
  )
}